<template>
  <b-card-code>
    <div v-if="$can('read','Class Routine')" class="custom-search d-flex mb-2">
      <router-link :to="{name:'class-routine'}">
        <b-button variant="outline-primary">
          Class Routine
        </b-button>
      </router-link>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="routine_add">
        <b-row>
          <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="class"
                rules="required"
            >
              <select name="classes_id" @change="filterSections" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{cls.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
              <b-card-text>
                <span>Select Versions </span>
              </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="version"
                rules="required"
            >
              <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
              <b-card-text>
                <span>Select Shifts </span>
              </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="shift"
                rules="required"
            >
              <select name="shift_id" v-model="shift_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">{{shift.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
              <b-card-text>
                <span>Select Institution </span>
              </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="wing"
                rules="required"
            >
              <select name="wing_id" @change="filterSections" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{wing.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Student Groups </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="student group"
                rules="required"
            >
              <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">{{sgrp.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Academic Years </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="academic year"
                rules="required"
            >
              <select name="academic_year_id" @change="filterSections" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(aYear, key) in active_academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Section </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="section"
                rules="required"
            >
              <select name="section_id" v-model="section_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(section, key) in filter_sections" :value="section.id" :key="key">{{section.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Subject </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="subject"
                rules="required"
            >
              <select name="subject_id" v-model="subject_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(subject, key) in subjects" :key="key" :value="subject.id">{{subject.name+' '+ subject.code}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>Select Class day </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="class day"
                rules="required"
            >
              <select name="class_day_id" v-model="class_day_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(cd, key) in active_class_days" :value="cd.id" :key="key">{{cd.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Class Period </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="class period"
                rules="required"
            >
              <select v-model="class_period_id" name="class_period_id" class="form-control"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                <option  :value="null">Select One</option>
                <option v-for="(period, key) in active_class_periods" :key="key" :id="period.start_time" :value="period.id">{{moment(period.start_time,'hh:mm').format('hh:mm a') + '-'+ moment(period.end_time,'hh:mm').format('hh:mm a')}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Need approval?</span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="need approval"
                rules="required"
            >
              <b-form-radio-group
                  v-model="is_approved"
                  :options="options"
                  stacked
                  name="is_approved"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4" v-if="is_approved===0">
            <b-card-text>
              <span>Select Employee </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="employee"
                rules="required"
            >
              <select v-model="employee_id" name="employee_id" class="form-control"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                <option  :value="null">Select One</option>
                <option v-for="(employee, key) in employees" :key="key" :id="employee.id" :value="employee.id">{{ employeeNameById(employee.id) +' '+ employeeIdById(employee.id)}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton,BCardText,
  BFormRadioGroup
} from 'bootstrap-vue'
import {
  required,integer
} from '@validations'
import apiCall from "@/libs/axios";
import {mapGetters} from "vuex";
import moment from "moment"
export default {
  name:'ClassRoutineAdd',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormRadioGroup,
  },
  data() {
    return {
      moment,
      is_approved: 1,
      options: [
        { text: 'Yes', value: 0 },
        { text: 'No', value: 1 },
      ],
      required,
      integer,
      classes:[],
      versions:[],
      student_groups:[],
      shifts:[],
      wings:[],
      all_sections:[],
      filter_sections:[],
      employee_id:null,
      student_group_id:null,
      version_id:null,
      shift_id:null,
      classes_id:null,
      class_day_id:null,
      class_period_id:null,
      academic_year_id:null,
      wing_id:null,
      section_id:null,
      subject_id:null,
    }
  },
  created(){
    this.getAll();
    this.$store.dispatch('GET_ACTIVE_CLASS_DAY');
    this.$store.dispatch('GET_ACTIVE_CLASS_PERIOD');
    this.$store.dispatch('GET_ACTIVE_ACADEMIC_YEAR');
    this.$store.dispatch('GET_ACTIVE_SUBJECT');
    if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.routine_add);
          apiCall.post('/class/routine/store',data).then((response)=>{
            if (response.data.status === 'success'){
              this.$toaster.success(response.data.message);
              //this.$refs.subject_add.reset();
              this.subject_id=null;
              this.class_day_id=null;
              this.class_period_id=null;
              this.$refs.simpleRules.reset();
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },

    /*
    * method for get all active class wise version, shift, student groups
    * */
    async getAll(){
      await apiCall.get('/get/active/class/with/v/s/sg/sections').then((response)=>{
          this.classes = response.data;
      }).catch(()=>{
        this.classes=[];
      });
    },

    /*
    * method for filter sections
    * */
    filterSections(){
      this.section_id=null;
        if (!this.wing_id || !this.academic_year_id) this.filter_sections=[];
        else{
          this.filter_sections = this.all_sections.map((item)=>{
            if (parseInt(item.pivot.wing_id) === this.wing_id && parseInt(item.pivot.academic_year_id) === this.academic_year_id) return item;
          }).filter(Boolean);
        }
    }
  },
  computed:{
    ...mapGetters(['active_class_days','active_academic_years','employees','employeeIdById','employeeNameById']),
    active_class_periods(){
      return this.$store.getters.active_class_periods.filter(item=>parseInt(item.type)===1);
    },
    subjects(){
      let sub = this.$store.getters.active_subjects;
      sub = sub.map((item)=>{
        if (parseInt(item.classes_id) === this.classes_id && parseInt(item.version_id) === this.version_id && parseInt(item.student_group_id) === this.student_group_id) return item;
      }).filter(Boolean);
      return sub;
    }
  },
  watch:{
    /*watcher for class on change*/
    classes_id(){
      let s_classes = this.classes.find(item=>parseInt(item.id)===this.classes_id);
      this.version_id=null;
      this.shift_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.subject_id=null;
      if (s_classes){
        this.versions = s_classes.versions??[];
        this.shifts = s_classes.shifts??[];
        this.wings = s_classes.wings??[];
        this.student_groups = s_classes.student_groups??[];
        this.all_sections = s_classes.sections??[];
      }else {
        this.versions =[];
        this.shifts =[];
        this.wings =[];
        this.student_groups =[];
        this.all_sections =[];
      }
    },
    version_id(){
      this.subject_id=null;
    },
    student_group_id(){
      this.subject_id=null;
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
